import React, { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import 'normalize.css'; // Reset styles for consistent baseline
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';

// Lazy load components
const PaperTradingList = lazy(() => import('./pages/TradingLists/PaperTradingList.jsx'));
const InstrumentList = lazy(() => import('./pages/InstrumentList'));
const LogicBuilder = lazy(() => import('./pages/LogicBuilder'));
const Settings = lazy(() => import('./pages/Config/Settings.js'));
const WorkflowCreate = lazy(() => import('./pages/Workflow/WorkflowCreate'));
const WorkflowList = lazy(() => import('./pages/Workflow/WorkflowList.jsx'));
const MarketEvents = lazy(() => import('./pages/MarketEvents.js'));
const Login = lazy(() => import('./pages/login.js'));
const PythonList = lazy(() => import('./pages/Python/ListPython.jsx'));
const EditorPythonWrapper = lazy(() => import('./pages/Python/EditorPython.jsx'));
const SymbolPublisher = lazy(() => import('./pages/home/publish_new_symbol.jsx'));
const ChartsView = lazy(() => import('./pages/charts/chart_view.jsx'));
const PublishedInstruments = lazy(() => import('./pages/published_instruments.js'));
const UsersLists = lazy(() => import('./pages/settings/users_list.jsx'));

// Fallback UI while lazy loading
const Loading = () => <div className='text-center m-auto h-100'><span>StopLoss</span></div>;

const AppRoutes = () => (
  <Suspense fallback={<Loading />}>
    <Routes>
      <Route path="/" element={<PublishedInstruments />} />
      <Route path="/publish-new-symbol" element={<SymbolPublisher />} />
      <Route path="/paper-trading" element={<PaperTradingList />} />
      <Route path="/market-instruments" element={<InstrumentList />} />
      <Route path="/logic-builder" element={<LogicBuilder />} />
      <Route path="/settings" element={<Settings />} />
      <Route path="/market/events" element={<MarketEvents />} />
      <Route path="/login" element={<Login />} />
      <Route path="/charts" element={<ChartsView />} />
      <Route path="/users-lists" element={<UsersLists />} />
      <Route path="/logic-flow/lists" element={<WorkflowList />} />
      <Route path="/logic-flow/create" element={<WorkflowCreate />} />
      <Route path="/logic-flow/edit/:id" element={<WorkflowCreate />} />
      <Route path="/python-code/list" element={<PythonList />} />
      <Route path="/python-code/edit/:id" element={<EditorPythonWrapper />} />
      {/* Add a catch-all route for 404 */}
      <Route path="*" element={<div>404 Not Found</div>} />
    </Routes>
  </Suspense>
);

export default AppRoutes;
